import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { t } from "i18next";
import SpeechBubbleIconImg from "shared/assets/Icons/speech-bubble.svg";
import PhoneIconImg from "shared/assets/Icons/phone-call.svg";
import VideoCallIconImg from "shared/assets/Icons/video-call-square.svg";
import CallbackIconImg from "shared/assets/Icons/callback.svg";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { mailto } from "core/javascriptChannels";
import { useMobilePlatform } from "core/hooks/useMobilePlatform";


export const WisdomLiteNotice = () => {

  const { isMobileApp } = useMobilePlatform();
  const handleFindMoreButtonClick = () => {

    const path = t("contactUs.wisdomLite.contactAddress") + "?subject=" + t("contactUs.wisdomLite.emailSubject");
    if (isMobileApp) {
      mailto(path)
    } else {
      window.open("mailto:" + path)
    }
  }

  return <div className="w-full py-4 md:p-4 md:w-1/2">
    <RoundedCard>
      <h4 className="font-semibold text-black">{t("contactUs.wisdomLite.title")}</h4>
      <div className="flex flex-col gap-3">
        <p>{t("contactUs.wisdomLite.description.line1")}</p>
        <p><img src={SpeechBubbleIconImg} alt={"Speech Bubble Icon"} className={"w-5 float-left mr-5"}/>{t("contactUs.wisdomLite.description.line2")}</p>
        <p><img src={PhoneIconImg} alt={"Phone Icon"} className={"w-5 float-left mr-5"}/>{t("contactUs.wisdomLite.description.line3")}</p>
        <p><img src={VideoCallIconImg} alt={"Video Call Icon"} className={"w-5 float-left mr-5"}/>{t("contactUs.wisdomLite.description.line4")}</p>
        <p><img src={CallbackIconImg} alt={"Callback Icon"} className={"w-5 float-left mr-5"}/>{t("contactUs.wisdomLite.description.line5")}</p>
        <p>{t("contactUs.wisdomLite.description.line6")}</p>
        <p className="text-sm flex flex-col gap-1">
          <StylableButton
            color={"primary"}
            fullWidth={true}
            onClick={() => handleFindMoreButtonClick()}
            text={t("contactUs.wisdomLite.buttonText")}/>
        </p>
      </div>
    </RoundedCard>
  </div>
}